import React from 'react'
import "./careerOption.css"
const CareerOption = ({title, p1 ,p2 ,p3}) => {
  return (
    <div className='career-option-container'>
        <h1>{title}</h1>
        <div className='career-option-data'>
            <h3>Open Positions</h3>
            <h4>{p1}</h4>
            <h4>{p2}</h4>
            <h4>{p3}</h4>
        </div>
    </div>
  )
}

export default CareerOption
