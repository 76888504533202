import React , {useEffect}from 'react'
import './IndustriesComponent.css'
import AOS from 'aos';
import 'aos/dist/aos.css';


const IndustriesComponent = ({ page, name, paragraph, imagesource, Num, right }) => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
    
    <div className={right ? 'Industries-container-right' : 'Industries-container'} data-aos="fade-right" data-aos-duration="500">
       <h1 className={right ? 'overlay-number-right' : 'overlay-number'}>{Num}</h1>

      <div className='Industries-data'>
        <h4>{page}</h4>
        <h2>{name}</h2>
        <p align='justify'>{paragraph}</p>
      </div>
      <img  data-aos='zoom-in' data-aos-easing="ease-in-back"
     data-aos-duration="600"
     data-aos-offset="0"className={right ? 'Industries-image-right1' : 'Industries-image1'} src={imagesource} alt="imagesforindustries" />
    </div>
    </>
  )
}

export default IndustriesComponent
