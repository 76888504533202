import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home/Home.js'
import Join_us from '../Join_us/Join_us.js'
import Services from '../Services/Services.js'
import Industries from '../Industries/Industries.js'
import Contact from '../Contact/Contact.js'
import About from '../About/About.js'
import TermAndConditin from '../TermAndCondition/TermAndConditin.js'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy.js'

const Main = () => {
  return (


      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/industries' element={<Industries/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/join-us' element={<Join_us/>}/>
        <Route path='/terms-and-conditions' element={<TermAndConditin/>}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>

      </Routes>
  )
}

export default Main
