import React from 'react'
import './middleSection.css'
import IndustriesComponent from '../../Common/IndustriesComponent'
import Slider from 'react-slick'
import { GrFormNext } from 'react-icons/gr'
import { GrFormPrevious } from 'react-icons/gr'


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="div-icon">
      <GrFormNext
        className={className}
        size={40}
        style={{ color: 'var(--p-red)' }}
        onClick={onClick}
      /> </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (

    <GrFormPrevious
      className={className}
      size={40}
      style={{ color: 'var(--p-red)', zindex: '9' }}
      onClick={onClick}
    />
  );
}

const MiddleSection = () => {

  const data = [
    { title: 'E-commerce', data: 'Demand for frictionless experiences is extending deeper and deeper into e-commerce from finding the right products, to the payments process, and speed and ease of delivery. New technologies like digital voice assistants and blockchain are opening new opportunities for commerce every day. It’s time to evolve the e-commerce experience and expand what users are purchasing on your platform with seamless, personalized and convenient new products and services.', imagesource: require('../../Assets/E-cmmerce.jpg'), Num: 'One' },
    { title: 'Healthcare Industry', data: 'Healthcare companies have the opportunity to create consumer experiences to keep the individual at the forefront through personalized technologies and programs. Our committed professionals operate at the intersection of business and technology to combine real-world experience, clinical and business insights and new, enabling intelligent technologies to deliver the power of Insight Driven Health in a demanding new digital world.', imagesource: require('../../Assets/healthcare.jpg'), Num: 'Two' },
    { title: 'Renewable Energy', data: 'Global community is constantly looking for affordable and sustainable energy resources, companies know that powering our world means thinking beyond industry definitions to push performance boundaries, redefine markets and pursue innovation. Newer technologies have opened doors to new dimensions of energy creation & reducing carbon foot-print. Renewable energy can transform process chain & operational costs for organisations.', imagesource: require('../../Assets/Renewable.jpg'), Num: 'Three' },
    { title: 'Insurance', data: 'At Dec6, we work closely with our clients to embrace the power of disruptive technologies, allowing them to drive innovation and sustainable growth for their enterprise. We’ll pair your firm with the right technology tools to help you realize your business goals. New technologies can uplift the value chain immensely.', imagesource: require('../../Assets/Insurance.jpg'), Num: 'Four' },
    { title: 'Aerospace', data: 'More and more aerospace companies are embracing digital technologies like AI, analytics and automation. Forecasts suggests immense potential for aerospace industry to grow manifold in coming years. Disruptive technologies have opened new ways of transforming the costs  operations.', imagesource: require('../../Assets/Aerospace.jpg'), Num: 'Five' },
    { title: 'Aviation', data: 'Intense competition, volatile fuel prices, and changing customer preferences and loyalties are just a few of the factors that make aviation services an intensely demanding industry. Dec6 helps aviation and airport management companies take swift, decisive action and make smart decisions about the strategic, operational and digital challenges that will determine their success', imagesource: require('../../Assets/Avaitin.jpg'), Num: 'Six' },

  ]

  const settings = {
    swipeable: true,
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <div className=''>

      <h1 className='title-overlay'>Industries </h1>
      <div>

      
      {data.map((item, idx) => (
        idx % 2 === 0 ? <IndustriesComponent
          key={idx}
          page={"Industries"}
          name={item.title}
          paragraph={item.data}
          imagesource={item.imagesource}
          Num={item.Num}
          right={false}
        /> :
          <IndustriesComponent
            key={idx}
            page={"Industries"}
            name={item.title}
            paragraph={item.data}
            imagesource={item.imagesource}
            Num={item.Num}
            right={true}
          />


      ))}</div>


    </div>
  )
}

export default MiddleSection
