import React, { useEffect } from 'react'
import './AboutSection.css'
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom'
import { Container, Grid } from '@mui/material'

const AboutSection = ({
  title,
  heading,
  para,
  imagesource,
  emaildrop,
  button,
  link
}) => {
  const Navigate = useNavigate()

  useEffect(()=>{
    AOS.init();
  },[])
  return (

    <Container className='about-container'>
      <Grid container  data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
        <Grid item sm={6} xs={12} className='about-col-1'>
          <img className='about-image' src={imagesource} alt='aboutSection' />
        </Grid>
        <Grid item sm={6} xs={12} className='about-col-2' >  
          <h6>{heading}</h6>
          
          <h2>{title} </h2>
          {/* <hr className='down-line'/> */}
          <p align='justify'>{para}</p>
          {emaildrop ? <a href='mailto:careers@dec6.in'>Drop Your CV</a> : ''} 
          {/* {emaildrop ? <a href=''>Explore Dec6</a> : ''}  */}
          {button ? (
            <button className='common-btn'  onClick={() => Navigate(`${ link }`)}>Learn More</button>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutSection
