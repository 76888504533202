import React , {useEffect} from 'react'
import Slider from 'react-slick'
import './Testimonials.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  HiOutlineArrowSmallLeft,
  HiOutlineArrowSmallRight
} from 'react-icons/hi2'
import { Container, Grid } from '@mui/material'
const TestCard = ({ person, title, para, company , imagesource, imagebg}) => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <Container>
      <Grid container  className='t-card-container'>
        <Grid item xs={12} sm={6} className='t-card-col1' data-aos= 'fade-right' >
          <img className='t-image' src={imagebg}  style={{borderRadius:'5px'}}/>
        </Grid>
        <Grid item sm={6} xs={12} className='t-card-col2' data-aos='fade-left'>
          <h1 align='justify'>{title}</h1>
          <p align='justify'>{para}</p>
          <div className='t-card-person'>
            <img src={imagesource} alt='Test' />
            <div className='t-card-person-data'>
              <h4>{person}</h4>
              <p>{company}</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

function SampleNextArrow (props) {
  const { onClick } = props
  return (
    <HiOutlineArrowSmallRight
      className='nextArrow'
      size={10}
      style={{ color: 'var(--p-red)' }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow (props) {
  const { onClick } = props
  return (
    <HiOutlineArrowSmallLeft
      className='prevArrow'
      size={10}
      style={{ color: 'black', zindex: '9' }}
      onClick={onClick}
    />
  )
}

const TestCarasouel = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    
  }

  const data = [
    {
      title: 'Helping everyone to be fit and live healthy life',
      para: 'Dec6 consulting practice provides professional IT services which can truly transform your business & help you achieve greater outcomes. Consultants at Dec6 are skilled, deliver excellent professional services.',
      person:'Liam',
      company:'FitExp',
      imagebg:require('../../Assets/fit.jpg'),
      imagesource:require('../../Assets/Mark.jpeg'),
    },
    {
      title: 'Making Every Platform Secure using Cyber Sec',
      para: 'We are quite impressed with the quality of the service & the engagement with Dec6. Their domain expertise in cyber security has extensively helped us in building our IT solutions more secure & robust',
      
      person:'Mark',
      company:'Mark & Co',
      imagebg:require('../../Assets/cs.jpg'),
      imagesource:require('../../Assets/Liam.jpg'),
    },
    {
      title: 'Customizing Digital Product for Pharama Companies',
      para: 'The product is great. Easy to use, customisable & fit for purpose. Product support team is quick to reply and very helpful. Dec6 product team provides excellent service, definitely recommend for future purposes.',
      person:'Gaurav',
      company:'P Pharma',
      imagebg:require('../../Assets/pharma.jpg'),
      imagesource:require('../../Assets/Gaurav.jpg'),
    },
  ]

  return (
    <div className='test-slider'>
      <Slider {...settings}>
        {
          data.map((item,key)=>(
            <div key={key}>
          <TestCard
            title={item.title}
            para={item.para}
            person={item.person}
            company={item.company}
            imagesource={item.imagesource}
            imagebg={item.imagebg}
          />
        </div>
          ))
        }

      </Slider>
    </div>
  )
}

export default TestCarasouel
