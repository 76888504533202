import React from 'react'
import IndustriesComponent from '../../Common/IndustriesComponent'

const MiddleSection = () => {
  const data = [
    {
      title: 'IT Consulting',
      num: 'One',
      imagesource: require('../../Assets/IT.jpg'),
      data: 'Our consulting practice is designed for clients looking for bespoke IT services under a business plan or a consumer plan. Dec6 consultants work out to the precise requirements for our clients & deliver a best in class service to build long term relationships. At Dec6, we focus to every detail of your IT needs.'
    },
    {
      title: 'Product Innovation',
      num: 'Two',
      imagesource: require('../../Assets/productinnov.jpg'),
      data: 'We help our clients develop new products and services that meet the needs of their customers and give them a competitive advantage. We use a variety of methods to innovate, including brainstorming, prototyping, and market research. We also have a team of experienced engineers and designers who can help our clients bring their ideas to life. We have a proven track record of success in helping our clients innovate.'
    },
    {
      title: 'Software Engineering',
      num: 'Three',
      imagesource: require('../../Assets/SE.jpg'),
      data: 'Our Engineering COE, provides widerange of services from idea conception to operational service. At Dec6, Engineers build masterclass IT products for our esteemed enterprise clients & individual consumer needs.'
    },
    {
      title: 'Digital',
      num: 'Four',
      imagesource: require('../../Assets/digitalwellbeing.jpg'),
      data: 'The digital age provides endless opportunities to unlock commercial growth. By developing new digital business models, investing in digital channels, and leveraging new technologies, you can acquire new customers, keep more of your existing customers, and ultimately grow your revenues and profits. Our digital consulting team has a dedicated focus on commercial growth and helps clients optimize the entire customer journey by combining technology, data, and creativity with commercial consulting expertise.'
    },
    {
      title: 'Analytics',
      num: 'Five',
      imagesource: require('../../Assets/analytic.jpg'),
      data: 'Data insights are found through the deep analysis of patterns and statistics within data. Through the interpretation of these patterns, organizations transform insights into forecasting customer needs, therefore promoting more effective decision-making which enables them to implement right strategies on time.'
    },
    {
      title: 'Cyber Security',
      num: 'Six',
      imagesource: require('../../Assets/CyberSecurity.jpg'),
      data: 'Cyber security has become an essential element of Digital World, with growing demand of IT enabled services cyber threats are imminent. Our specialised cyber security team, provides full range of services like vulnerability assessment, security testing, Data Privacy & Protection, IAM, etc'
    }
  ]

  return (
    <div>
      <h1 className='title-overlay'>Services</h1>
      {data.map((item, idx) =>
        idx % 2 === 0 ? (
          <IndustriesComponent
            key={idx}
            page={"Services"}
            name={item.title}
            paragraph={item.data}
            imagesource={item.imagesource}
            Num={item.num}
            right={false}
          />
        ) : (
          <IndustriesComponent
            key={idx}
            page={"Services"}
            name={item.title}
            paragraph={item.data}
            imagesource={item.imagesource}
            Num={item.num}
            right={true}
          />
        )
      )}
    </div>
  )
}

export default MiddleSection
