import React, { useEffect } from 'react'
import './SectionIntro.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionIntro = ({title, para ,imagesource,right}) => {
  useEffect(()=>{
    AOS.init();
  },[])
    return (
    <div className='SI-container'>
      <div className={right? 'SI-data-right':'SI-data'} data-aos='fade-left'>
        <h1 >{title}</h1>
        <p align ='justify'>{para} </p>
        <a href='/about'>Get to know more</a>
      </div>
      <img className={right? 'SI-container-img-right':'SI-container-img'} data-aos='zoom-in' data-aos-easing="ease-in-back"
     data-aos-duration="600"
     data-aos-offset="0"src ={imagesource} alt="SectionIntro"/>
    </div>
   
  )
}

export default SectionIntro