import './App.css';
import Header from './Common/Header';
import Footer from './Common/Footer';
import Main from './Common/Main';
import ChatbotSection from './Common/ChatbotSection';



function App() {
  return (
    <div className="App">
      <Header/>
       <Main/>
       <ChatbotSection/>
      <Footer/>
    </div>
  );
}
    
export default App;
