import React from 'react'

const Bar = () => {
  return (
    <div className='IC-colorgrid'>
      <div className='c-blue'/>
      <div className='c-red'/>
      <div className='c-green'/>
      <div className='c-yello'/>
      <div className='c-purple'/>
    </div>
  )
}

export default Bar
