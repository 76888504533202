import React, { useState, useEffect } from 'react'
import './Header.css'
import LOGO from '../Assets/logo.svg'
import { BiMenuAltLeft } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { IoCloseSharp } from 'react-icons/io5'

const Header = () => {
  const [sidebar, setsidebar] = useState(false)

  const handleSidebar = () => {
    setsidebar(!sidebar)
  }
  return (
    <>
      <div className={sidebar ? 'sidebar-menu-open' : 'sidebar-menu-close'}  >
        <ul className='sidebar-links'>
          <li>
            {' '}
            <NavLink
              className='lili'
              onClick={() => setsidebar(!sidebar)}
              to={'/'}
            >
              Home
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink
              className='lili'
              onClick={() => setsidebar(!sidebar)}
              to='/about'
            >
              About us
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink
              className='lili'
              onClick={() => setsidebar(!sidebar)}
              to='/industries'
            >
              Industries
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink
              className='lili'
              onClick={() => setsidebar(!sidebar)}
              to='/services'
            >
              Our Services
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink
              className='lili'
              onClick={() => setsidebar(!sidebar)}
              to='/join-us'
            >
              Join Us
            </NavLink>{' '}
          </li>
          <li>
            {' '}
            <NavLink
              className='lili'
              onClick={() => setsidebar(!sidebar)}
              to='/contact'
            >
              Contact Us
            </NavLink>{' '}
          </li>
        </ul>
      </div>
      <div className='header-container'>
        <div className='header-logo-menu'>
          <div className='header-icons' onClick={() => handleSidebar()}>
            {sidebar ? <IoCloseSharp size={50} /> : <BiMenuAltLeft size={50} />}
          </div>
          <img src={LOGO} style={{ width: '150px',cursor:'pointer' }} alt='dec6-logo' onClick={() => window.location.href='/'}/>
        </div>
        <ul className='header-links'>
          <li className='lili'>
            {' '}
            <NavLink className='lili' active='active' to='/'>
              Home
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink className='lili' to='/about'>
              About us
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink className='lili' to='/industries'>
              Industries
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink className='lili' to='/services'>
              Our Services
            </NavLink>
          </li>
          <li>
            {' '}
            <NavLink className='lili' to='/join-us'>
              Join Us
            </NavLink>{' '}
          </li>
          <li>
            {' '}
            <NavLink className='lili' to='/contact'>
              Contact Us
            </NavLink>{' '}
          </li>
        </ul>
      </div>
      
    </>
  )
}

export default Header
