import React, { useEffect } from 'react'
import "./ContactMiddle.css"
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Aos from 'aos';

function Card ({title, para, para1, btn_title, link}){

  const Navigate = useNavigate();
  useEffect(()=>{Aos.init();
  },[])
  return (
    <>
    <div className='card'  data-aos="flip-up"data-aos-duration="1000" data-aos-delay="200">
      <h1>{title}</h1>
      <div className='card-data'>
        <p>{para} </p>
        <p>{para1}</p>
        
        <a className='cardlink' href={link} ><button >{btn_title}</button></a>
      </div>
      </div>
    </>
  )
}

const MiddleSection = () => {
  return (
    <div className='common-wrapper contact-middle'>
      <Card  title={"Office"} para={"Dec6 Consulting Private Limited"} para1={"2/62, Ashri Bhawan, Shivaji Nagar, Gurugram, Haryana India - 122001"}  btn_title={'Email Us'} link='mailto:info@dec6.in' />
      <Card  title={"New Business"} para={"Tell us your interest ->"}  btn_title={'Tell us!'} link={'mailto:business@dec6.in'}/>
      <Card  title={"Career"}para={"Take a bold step forward to kickstart your career"}  btn_title={'Join us '} link={'./join-us'}/>

    </div>
  )
}

export default MiddleSection
