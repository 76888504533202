import React from 'react'
import TestCarasouel from './TestCarasouel'
import './Testimonials.css'
const Testimonials = () => {
  return (
    <div className='common-wrapper'>
      <div className='test-data'>
        <h1>How we've helped!</h1>
        <hr />
      </div>
      <div className=''>
        <TestCarasouel />
      </div>
    </div>
  )
}

export default Testimonials
