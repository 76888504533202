import React,{useEffect} from 'react'
import './topSection.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const CareerCard = ({ title, para, imagesource }) => {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <div className='career-container' data-aos="flip-up" data-aos-duration="1000" data-aos-delay="200">
      <h6>Career</h6>
      <img className='career-image' src={imagesource} alt='Image ' />
      <div className='career-data'>
        <h1>{title}</h1>
        <p align='justify'>{para}</p>
      </div>
    </div>
  )
}

const JoinTop = () => {
  return (
    <div className='common-wrapper'>
      <h1 className='jt-heading'>Careers</h1>
      <hr className='jt-hr'/>
      <div className='career-grid'>
        <CareerCard
          title={'Fresh Graduates'}
          para={
            'Our graduate schemes are now open for applications. We’ve opportunities for graduates in every area of our business so whatever your degree, you’ll find something exciting, challenging, and unexpected at Dec6. We’re constantly strive to learn and grow, and with your help, together, we can.'
          }
          imagesource={require('../../Assets/fresh.jpg')}
        />
        <CareerCard
          title={'Experienced'}
          para={
            'We’ve opportunities for experienced professionals in every area of our business so whatever your degree, you’ll find something exciting, challenging, and unexpected at Dec6. We’re constantly strive to learn and grow, and with your help, together, we can. Explore new possibilities and elevate your career.'
          }
          imagesource={require('../../Assets/Experienced.jpg')}
        />
        <CareerCard
          title={'Apprenticeship'}
          para={
            'We are now accepting applications to our Apprentice schemes. No matter what you are looking for, we have apprenticeships for everyone. We don t have an age limit on our apprenticeships, so as long as you meet the entry requirements, we are happy to hear from you.'
          }
          imagesource={require('../../Assets/apprentice.jpg')}
        />
      </div>
    </div>
  )
}

export default JoinTop
