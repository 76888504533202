import React from 'react'
import Getintouch from '../../Common/Getintouch'
import Testimonials from './Testimonials'

const BottomSection = () => {
  return (
    <div>
      <Testimonials/>
      <Getintouch/>
    </div>
  )
}

export default BottomSection
