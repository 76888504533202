import React, { Component } from 'react'
import Slider from 'react-slick'
import './HeroSection.css'
import HeroCard2 from './HeroCard2'
import Bar from '../../Common/Bar'

import {
  HiOutlineArrowSmallLeft,
  HiOutlineArrowSmallRight
} from 'react-icons/hi2'

function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <HiOutlineArrowSmallRight
      className='nextArrow'
      size={10}
      style={{ color: 'var(--p-red)' }}
      onClick={onClick}
    />
  )
}



function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <HiOutlineArrowSmallLeft
      className='prevArrow'
      size={10}
      style={{ color: 'black', zindex: '9' }}
      onClick={onClick}
    />
  )
}




const HeroSection = () => {
  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <div className='slider-outer-div'>
      <Slider {...settings}>
        <HeroCard2
          Title={'Digital'}
          Heading={'Explore how we help our Clients'}
          para={
            "Through our expansive industry experience, we help to create integrated, end-to-end digital solutions underpinned by robust strategy and innovation to solve our clients' most complex business challenges."
          }
          imagesource={require('../../Assets/slide1.jpg')}
        />
        <HeroCard2
          Title={'IT Consulting'}
          Heading={'Explore how we help our Clients'}
          para={
            "With rich experience in IT Consulting, our digital consultants transform your business, always working towards excellence and take you beyond the horizon and achieve greater success."
          }
          imagesource={require('../../Assets/IT.jpg')}
        />
      </Slider>
      <Bar />
    </div>
  )
}

export default HeroSection
