import React from 'react'
import './Footer.css'
import Logo from '../Assets/logo.svg'
import { FaFacebook } from 'react-icons/fa'
import { BiLogoInstagramAlt } from 'react-icons/bi'
import { IoLogoWhatsapp } from 'react-icons/io'
import { FaXTwitter } from 'react-icons/fa6'
import { FaLinkedin } from 'react-icons/fa'
const Footer = () => {
  return (
    <div className='common-wrapper F-container'>
      <div className='f-follow'>
        <h1>Follow</h1>
        <div className='f-follow-icons'>
          <a target='_blank' href='https://www.facebook.com/dec6consulting/'>
            <FaFacebook className='icon-hover' />
          </a>
          <a target='_blank' href='https://www.instagram.com/dec6consulting/?igshid=13uejqzwrqm0d'>
            <BiLogoInstagramAlt className='icon-hover' />
          </a>
          <a target='_blank' href='https://twitter.com/i/flow/login?redirect_after_login=%2FDec6Consulting'>
            <FaXTwitter className='icon-hover' />
          </a>
          <a target='_blank' href='https://www.linkedin.com/company/dec6consulting/'>
            <FaLinkedin className='icon-hover' />
          </a>

          <a target='_blank' href='https://api.whatsapp.com/message/7DOSVHMKRIZUM1'>
            <IoLogoWhatsapp className='icon-hover' />
          </a>
        </div>
      </div>
      <img style={{ width: '150px' }} src={Logo} alt='dec6-logo' />
      <div className='f-links'>
        <h6> &#169; Dec6 Consulting Private Limited</h6>
        <span className='barrs'>|</span>
        <ul className='f-list'>
          <li>
            <a href='/'>Home</a>
          </li>
          <li>
            <a href='/about'>About us</a>{' '}
          </li>
          <li>
            <a href='/services'>Services</a>
          </li>
          <li>
            <a href='/industries'>Industries</a>
          </li>
          <li>
            <a href='/contact'>Contact us</a>
          </li>
          <li>
            <a href='/join-us'>Join us</a>
          </li>
          <li>
            <a href='terms-and-conditions'>Terms and Conditions</a>
          </li>
          <li>
            <a href='privacy-policy'>Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
