import React from 'react'
import ImageComponent from '../../Common/ImageComponent'
import AboutSection from '../../Common/AboutSection'
const TopSection = () => {
  return (
    <div>
      <ImageComponent title={'Services'} imageSource={require('../../Assets/Services.jpg')}/>
      <AboutSection title={"Why Choose Us!"}para={"To explore, innovate & create groundbreaking sustainable digital solutions for modern day challenges. Build a creative, inclusive & diverse organisation to help our clients create high levels of value that together we set new standards of excellence in our respective industries and make a positive impact on communities around us."} heading={"Work with us"} imagesource={require('../../Assets/Whyus.jpg')}/>
    </div>
  )
}

export default TopSection
