import React from 'react'
import Getintouch from '../../Common/Getintouch'
import ServicesCardCarousel from './ServicesCardCarousel'
import SectionIntro from '../../Common/SectionIntro'

const BottomSection = () => {
  return (
    <div>
      <ServicesCardCarousel/>
      <SectionIntro
      para={"We help businesses successfully navigate digital transformation and drive real growth, drawing on the combined power of experience and contextual knowledge, across a vast ecosystem of expertise."}
      imagesource={require('../../Assets/digitalwellbeing.jpg')} title={"Dec6 transforms businesses through technology."}
      right={true}/>
      <Getintouch/>
    </div>
  )
}

export default BottomSection
