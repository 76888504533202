import React from 'react'
import './ImageComponent.css'
import Bar from './Bar'

const ImageComponent = ({title, imageSource}) => {
  return (
    
    <>
    <div className='IC-container'>
        <div className='overlays'/>
        <img src={imageSource} alt ="container-background"/>
        <h1 >{title}</h1>
    </div>
    <Bar/>
    </>
  )
}

export default ImageComponent
