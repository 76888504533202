
import TopSection from './TopSection/TopSection'
import MiddleSection from './MiddleSection/MiddleSection'
import BottomSection from './BottomSection/BottomSection'
import SEO from '../Common/SEO'


const Services = () => {
  return (
    <div>
      <SEO  title={'Services | Dec6 Consulting Private Limited'}  description={"To explore, innovate & create groundbreaking sustainable digital solutions for modern day challenges. Build a creative, inclusive & diverse organisation to help our clients create high levels of value that together we set new standards of excellence in our respective industries and make a positive impact on communities around us."} 
       link={"https://www.dec6.in/services"} name={'Dec6'} type={'Services'}/>
    
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
    </div>
  )
}

export default Services
