import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import AOS from 'aos';
import 'aos/dist/aos.css';



const BottomSection = () => {
  useEffect(()=>{AOS.init();},[])
  return (<>
    <div style={{margin:'50px auto',textAlign:'center'}} data-aos="zoom-out-up">
      <h2>Connect With Us</h2>
      <p style={{marginBottom:'inherit'}}>We would love to hear from you and answer any questions you may have</p>
      {/* <a href={'mailto:info@dec6.in'} className='common-btn' >Contact us </a> */}
      <a href={''} className='common-btn' >Contact us </a>
    </div>
  </>
    
  )
}

export default BottomSection