import React, {useEffect} from 'react'
import ImageComponent from '../../Common/ImageComponent.js'
import AOS from 'aos'
import 'aos/dist/aos.css';
import Card from './Card.js'
import './card.css'
import AboutSection from '../../Common/AboutSection.js'
const TOICard =({title, imagesource})=>{
  useEffect(() => {
    AOS.init();
  }, [])
  return(
    <>
    <div className='TOICard-container '  data-aos="fade-zoom-in"
     data-aos-easing="ease-in-back"
     data-aos-delay="300"
     data-aos-offset="0">
      <img src ={imagesource} alt='images'/>
      <div className='TOICard-data'>
      <h4>{title}</h4>
      </div>
    </div>
    </>
  )
}





const TopSection = () => {
  return (
    <div>
         <ImageComponent title={'Industries'} imageSource={require('../../Assets/Industries.jpg')} />
         <AboutSection title={"We have our hand in many Industries"} para={"“At Dec6, our people come first, and our vision, purpose, and core values guide our way of being as a company and how we work. We value each person 's uniqueness and encourage our team members to be their authentic selves, nurturing a culture of belonging and inclusion. We aspire for each person to love their work and life."}
         imagesource={require('../../Assets/industry.jpg')}
         />
         <h1></h1>
         <div className='Industry-Grid'>
          <TOICard  imagesource={require('../../Assets/B2B.jpg')} title={'Business to Enterprise'}/>
          <TOICard imagesource={require('../../Assets/B2C.jpg')} title={'Business to Customer'}/>
          <TOICard imagesource={require('../../Assets/B2G.jpg')} title={'Business to Government'}/>
         </div>
        
    </div>
  )
}

export default TopSection
