import React, { useEffect } from 'react'
import "./MiddleQuotes.css"
import AOS from 'aos'
import 'aos/dist/aos.css';
const MiddleQuotes = () => {

    useEffect(()=>{
        AOS.init();
    },[])
  return (
    <div className="MQ-container">
      <div className='MQ-image'/>
      <div className='MQ-col1'  data-aos='fade-up' data-aos-duration="500">
        <div>
        <h4>What we do</h4>
        <h2>Opportunities are everywhere</h2>
        </div>
        <p align='justify'>We help you see the world differently, discover opportunities you may never have imagined and unlock the potential to achieve desired results. Together, we help you explore endless possibilities to make this world better.</p>
      </div>
      <div className='MQ-col2'  data-aos='fade-down' data-aos-duration="500">
      <div>
        <h4>What we believe</h4>
        <h2>Take bold steps </h2>
        </div>
        <p align='justify'>We believe that the bold steps with the right intent & vision define the future. That the ambition to lead requires the courage to begin & adapt. And that doing the right thing is rarely easy, but always worth it.</p>
      </div>
    </div>
  )
}

export default MiddleQuotes
