import React from 'react'
import ImageComponent from '../../Common/ImageComponent'
import AboutSection from '../../Common/AboutSection'

const TopSection = () => {
  return (
    <div>
      <ImageComponent
        title={'About us '}
        imageSource={require('../../Assets/about.jpg')}
      />
      <AboutSection
        title={'Putting people first since day one'}
        para={
          "At Dec6, our people come first, and our vision, purpose, and core values guide our way of being as a company and how we work. We value each person's uniqueness and encourage our team members to be their authentic selves, nurturing a culture of belonging and inclusion. We aspire for each person to love their work and life."
        }
        heading={'What makes Dec6 different'}
        imagesource={require('../../Assets/aboutSectionCol1.jpg')}
      />
    </div>
  )
}

export default TopSection
