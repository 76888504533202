import React from 'react'
import JoinTop from '../TopSection/JoinTop'

const MiddleSection = () => {
  return (
    <JoinTop/>
  )
}

export default MiddleSection
