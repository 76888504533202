import React from 'react'
import OurMission from './OurMission'
import MiddleQuotes from './MiddleQuotes'

const MiddleSection = () => {
  return (
    <div>
      {/* <OurMission/> */}
      <MiddleQuotes/>
    </div>
  )
}

export default MiddleSection
