import React from 'react'
import Getintouch from '../../Common/Getintouch'


const BottomSection = () => {
  return (
    <div>
        <Getintouch/>
    </div>
  )
}

export default BottomSection