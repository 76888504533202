import React from 'react'
import ImageComponent from '../Common/ImageComponent'
import { Container } from '@mui/material'
import SEO from '../Common/SEO'

// Css is in App.css a of both Terms and Conditions and Privacy Policy as TP-container - tagname

const PrivacyPolicy = () => {
  return (
    <>
     <SEO  title={'Privacy policy | Dec6 Consulting Private Limited'}  description={"DEC6 is committed to protecting your privacy. You can visit most pages on our site without giving us any information about yourself. However, sometimes we do need information to provide services that you request and this privacy statement explains data collection and use in those situations. This privacy statement only applies to products, services and APIs developed & maintained by Dec6 Consulting Private Limited."} 
       link={"https://www.dec6.in/privacy-policy"} name={'Dec6'} type={'Privacy policy'}/>
    
      <ImageComponent
        title={'Privacy Policy'}
        imageSource={require('../Assets/privacy.jpg')}
      />
      <div>
        <Container className='TP-container'>
          <h1>Privacy Policy</h1>
          <p>
            DEC6 is committed to protecting your privacy. You can visit most
            pages on our site without giving us any information about yourself.
            However, sometimes we do need information to provide services that
            you request and this privacy statement explains data collection and
            use in those situations. This privacy statement only applies to
            products, services and APIs developed & maintained by Dec6
            Consulting Private Limited.
          </p>
          <p>
            BY USING THE SITE, PRODUCTS OR SERVICES, YOU ARE CONSENTING TO THIS
            PRIVACY POLICY. PLEASE READ IT CAREFULLY.
          </p>
          <h2>1.Data We may Collect</h2>
          <h3>Personal Information</h3>
          <p>
            Personal information collected by aforesaid DEC6 sites often is
            limited to e-mail address, language, country or location, but may
            include other information when needed to provide a service you
            requested. We may collect information that personally identifies
            you, such as your name, address, phone number, mobile phone number,
            e-mail, payment information, date of birth, and other personally
            identifiable information that you choose to provide us with or that
            you choose to include in your account (“Personal Information”). You
            may be asked to provide us with Personal Information when you
            register with the Site or use any of our Services, and at other
            times.
          </p>
          <h3>Usage Data and Site Activity</h3>
          <p>
            Our web servers whenever provide analytics and performance
            enhancement services also collects certain information about your
            computer hardware and software. This information may include: your
            IP address, browser type, operating system, domain name, access
            times and referring Website addresses. This information is used for
            the operation of the service, to maintain quality of the service and
            to provide general statistics regarding use of DEC6 sites.
          </p>
          <h3>Cookies and Anonymous Identifiers</h3>
          <p>
            When someone visits the site, a cookie is placed on the customer’s
            machine (if the customer accepts cookies) or is read if the customer
            has visited the site previously. One use of cookies is to assist in
            the collection of the site visitation statistics described above. We
            use cookies and other anonymous identifiers to analyze use of and
            improve the Site and Services. We do not use cookies to collect
            Personal Information. Certain features of the Site or Services may
            not work if you delete or disable cookies. Some of our Service
            Providers may use their own cookies, anonymous identifiers, or other
            tracking technology in connection with the services they perform on
            our behalf.
          </p>
          <h2>2.Use of Information & Disclosure</h2>
          <p>
            We use Personal Information for internal purposes only, such as to
            provide you with the Site and Services, including to send you alerts
            about your account, to improve the Site and Services, to notify you
            of new products or services, and to otherwise communicate with you
            about our Company. We may also use your Personal Information to
            provide more relevant search results from the search functions of
            the Site and the Services. We will not disclose Personal Information
            to third parties, including for their direct marketing purposes,
            without your consent, except as explained in this Privacy Policy. We
            may disclose to third parties, certain Usage Data regarding the Site
            and Services. However, in such cases, your Usage Data is aggregated
            with the Usage Data of others and does not identify you
            individually.
          </p>
          <h2>3.Compliance with Laws and Law Enforcement</h2>
          <p>
            We cooperate with government and law enforcement officials and
            private parties to enforce and comply with the law. We may disclose
            Personal Information and any other information about you to
            government or law enforcement officials or private parties if, in
            our discretion, we believe it is necessary or appropriate in order
            to respond to legal requests (including court orders), to protect
            the safety, property, or rights of our company or of any third
            party, to prevent or stop any illegal, unethical, or legally
            actionable activity, or to comply with the law.
          </p>
          <h2>4.Security</h2>
          <p>
            We maintain physical, electronic, and procedural safeguards to
            protect the confidentiality and security of information transmitted
            to us. However, no data transmission over the Internet or other
            network can be guaranteed to be 100% secure. As a result, while we
            strive to protect information transmitted on or through the Site or
            Services, we cannot and do not guarantee the security of any
            information you transmit on or through the Site or Services, and you
            do so at your own risk.
          </p>{' '}
          <h2>5.Data Processing & GDPR Compliance</h2>
          <p>
            Please be aware that your Personal Information and communications
            may be transferred to and maintained on servers or databases located
            outside your state, province, or country. If you are located outside
            of the India, please be advised that we process and store all
            information in the India. The laws in the India may not be as
            protective of your privacy as those in your location. By using the
            Site or Services, you are agreeing to the collection, use, transfer,
            and disclosure of your Personal Information and communications will
            be governed by the applicable laws in India.
          </p>
          <h3>European Data Protection Rights – GDPR</h3>
          <p>
            We collect the data to ensure a proper connection buildup, system
            security and to improve our website. The legal basis for such
            processing is Art. 6 para. 1 GDPR. We have a legitimate interest in
            the provision of our website and services, in technical security,
            rectification of malfunctions as well as detection and tracking of
            unauthorized access or attempt of access. DEC6 complies with
            applicable data protection laws in the European Economic Area, which
            if applicable, bestows you the following rights:
          </p>
          <ul>
            <li>
              If the processing of personal data is based on your consent, you
              have a right to withdraw consent at any time for future
              processing;
            </li>
            <li>
              Right to request from us, a “data controller” as defined in the
              law, access to and rectification of your personal data;
            </li>
            <li>
              Right to request from us that your personal data are erased,
              subject to certain exceptions;
            </li>
            <li>
              Right to object to the processing of your personal data; and
            </li>
            <li>
              Right to lodge a complaint with a data protection authority.
            </li>
          </ul>
          <h2>6.Enforcement & Data Protection Officer (DPO)</h2>
          <p>
            If you have questions regarding this statement and have any privacy
            concerns or complaints, you could direct it to our Data Protection
            Office on our email .
          </p>
          <h2>7.Changes to this Policy</h2>
          <p>
            DEC6 sites shall occasionally update this privacy statement. When we
            do, we will also revise the “last updated” date at the bottom of the
            privacy statement. For material changes to this statement, DEC6 will
            notify you by placing prominent notice on the Web site.
          </p>
          <br />
          <br />
          <p></p>Last updated on September 27, 2020 Privacy Notice subject to
          update from time to time.
        </Container>
      </div>{' '}
    </>
  )
}

export default PrivacyPolicy
