import React from 'react'
import ImageComponent from '../../Common/ImageComponent.js'

const TopSection = () => {
  return (
    <>
    <ImageComponent title={'Connect with us'} imageSource={require('../../Assets/contact.jpg')} />
      <div style={{margin:'30px auto',textAlign:'center'}}>
      <h2>Contact us</h2>
      <p>Have a question ? We would love to hear from you</p>
    </div>
    </> 
      
  )
}

export default TopSection
