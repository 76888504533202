import React from "react";
import Slider from "react-slick";
import "./ServicesCardCarousel.css"
import { Container } from "@mui/material";
import {
    HiOutlineArrowSmallLeft,
    HiOutlineArrowSmallRight
  } from 'react-icons/hi2'
  

const SCard = ({title, Category, imageSource})=>{
    return(
        <div className="SCard-container">
            <h4>{Category}</h4>
            <div className="SCard-contents">
                <img style={{width:"95% " , height:"350px", borderRadius:"5px", objectFit:'cover', }} src={imageSource} alt='cdf'/>
                <div className="SCard-data">
                    <h1>{title}</h1>
                    <a href= "/services">Read More</a>
                </div>
            </div>
        </div>
    )
}


  function SampleNextArrow (props) {
    const { className, style, onClick } = props
    return (
      <HiOutlineArrowSmallRight
        className='nextArrow'
        size={10}
        style={{ color: 'var(--p-red)' }}
        onClick={onClick}
      />
    )
  }
  
  
  
  function SamplePrevArrow (props) {
    const { className, style, onClick } = props
    return (
      <HiOutlineArrowSmallLeft
        className='prevArrow'
        size={10}
        style={{ color: 'black', zindex: '9' }}
        onClick={onClick}
      />
    )
  }
  

const ServicesCardCarousel = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
      };
  return (
    <Container className="service-container" >
        <h1 className="service-heading">Services</h1>
        <hr className="service-hr"/>
        <div className="slider-services">
            
        <Slider {...settings}>
            <div className="EachCard">
            <SCard  title={"Software Engineering"} Category={'Technology'} imageSource={require('../../Assets/SE.jpg')}/>
            </div>
            <div className="EachCard">
            <SCard  title={"Cyber Security"} Category={'Technology'} imageSource={require('../../Assets/CyberSecurity.jpg')}/>
            </div>
            <div className="EachCard">
            <SCard  title={"IT Consulting"} Category={'Technology'} imageSource={require('../../Assets/ITconsulting.jpg')}/>
            </div>
            <div className="EachCard">
            <SCard  title={"Analytics"} Category={'Technology'} imageSource={require('../../Assets/analytics.jpg')}/>
            </div>
            <div className="EachCard">
            <SCard  title={"Product Innovation"} Category={'Technology'} imageSource={require('../../Assets/productInov.jpg')}/>
            </div>
            <div className="EachCard">
            <SCard  title={"Digital Well Being"} Category={'Technology'} imageSource={require('../../Assets/Digital.jpg')}/>
            </div>
          </Slider>
        </div>
    </Container>
  )
}

export default ServicesCardCarousel
