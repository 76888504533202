import React from 'react'
import BottomSection from './BottomSection/BottomSection'
import TopSection from './TopSection/TopSection'
import MiddleSection from './MiddleSection/MiddleSection'
import { Container } from '@mui/material'
import { Helmet } from 'react-helmet'
import SEO from '../Common/SEO'

const Home = () => {
  return (
    <div>
       <SEO  title={'Home | Dec6 Consulting Private Limited'}  description={'Dec6 is an IT services, consulting and business solutions organization that has been partnering with many of the world’s largest businesses in their transformation journeys for over 4 years.'} 
       link={"https://www.dec6.in/"} name={'Dec6'} type={'Home'}/>
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
    </div>
  )
}

export default Home
