import React from 'react'
import "./OurMission.css"
function  ImageCard ({image_source}){
    return (
        <>
        <div className='image-card'>
            <img className='image-image' src={image_source} alt="cdcd"/>
        </div>
        </>
    )
}

const OurMission = () => {
  return (
    <div className='common-wrapper'>
      <div className='mission-top'>
        <div className='mission-top-col-1'>
            <h1>Our Mission</h1>
            <hr />
        </div>
        <div className='mission-top-col-2'>
            <p>To explore, innovate & create groundbreaking sustainable digital solutions for modern day challenges. Build a creative, inclusive & diverse organisation to help our clients create high levels of value that together we set new standards of excellence in our respective industries and make a positive impact on communities around us.</p>
        </div>
      </div>
      <div className='mission-bottom'>
        <ImageCard image_source={require('../../Assets/Industries.jpg')}/>
        <ImageCard image_source={require('../../Assets/bgimage.jpg')}/>
        <ImageCard image_source={require('../../Assets/contact.jpg')}/>
      </div>
    </div>
  )
}

export default OurMission
