import React from 'react'
import TopSection from './TopSection/TopSection'
import MiddleSection from './MiddleSection/MiddleSection'
import BottomSection from './BottomSection/BottomSection'
import SEO from '../Common/SEO'

const Join_us = () => {
  return (
    <div>
           <SEO  title={'Join us | Dec6 Consulting Private Limited'}  description={"We are a team of passionate and dedicated individuals who are committed to providing our customers with the best possible experience. We believe that our success is due to our strong work ethic, our commitment to excellence, and our dedication to our customers. We are always looking for new and talented individuals to join our team. If you are interested in working with a company that is committed to your success, then we encourage you to apply."} 
       link={"https://www.dec6.in/join-us"} name={'Dec6'} type={'Join us'}/>
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
    </div>
  )
}

export default Join_us
