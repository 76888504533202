import React from 'react'
import "./HeroCard2.css"
import Bar from '../../Common/Bar'
import {  Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const HeroCard2 = ({Heading,Title, para, imagesource}) => {
  const Navigate = useNavigate();
  return (
    <>

      <Grid container className='hero-card-container'>
        <Grid item sm={6} xs={12} className='hero-card-col-1'>
        <h6>{Heading}</h6>
        <h1>{Title}</h1>
        <p align='justify'>{para}</p>
        <button onClick={()=>Navigate('./services')}>Learn More</button>
        </Grid>
        <Grid item sm={6} xs={12} className='hero-card-col-2'>
        <img src={imagesource} alt="home"/>
        </Grid>
      </Grid>
    </>
  )
}

export default HeroCard2
