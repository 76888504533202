import React, { useEffect, useRef } from 'react'
import './ChatbotSection.css'
import { useState } from 'react'
import { ImCross } from 'react-icons/im'
import { AiFillMessage } from 'react-icons/ai'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import Logo from '../Assets/logo.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'
const ChatbotSection = () => {
  const [chatopen, setChatopen] = useState(false)
  const [openform, setOpenform] = useState(false)
  const [showbutton, setShowButton] = useState(false);
  const[name,setName]= useState('');
  const[email,setEmail]= useState('');
  const[phone,setPhone]= useState('');
  const[requirements,setRequirements]= useState('');
  const [categories,setCategories]=useState([])
  const [error,setError]= useState(false);
  const [submitted,setSubmitted]=useState(false)
    const [category,setCategory]=useState('')
    const myRef = useRef(null)


    const [processing,setProcessing]=useState(false)



const [submission,setSubmission]=useState('')
  const live='https://d6apicluster.herokuapp.com/'
  useEffect(()=>{
    fetch(`${live}categories`).then((res)=>res.json()).then(resp=>setCategories(resp))
    
  },[])
  useEffect(()=>{
    if(myRef&&myRef.current)
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
    
  },[submitted,category,submission])
  // const[processing,setProcessing]= useState(false);

  // Chatopen
  const handleChatopen = () => {
    setChatopen(!chatopen)
    setOpenform(false)
  }
  const Category=(val)=>{
    if(!category){

      setOpenform(true)
      executeScroll()

      setCategory(val)

    }
  }
  const executeScroll=()=>{

    if(myRef&&myRef.current)
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  

}
  // Backtotop function
  const backtotop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  // Scrolltotop Visibikity
  useEffect(() => {
    const handleScrollbuttonVisibility = () => {
      window.scrollY > 300 ? setShowButton(true) : setShowButton(false)
    }
    window.addEventListener('scroll', handleScrollbuttonVisibility)

    return () => {
      window.removeEventListener('scroll', handleScrollbuttonVisibility)
    }
  }, [])

const handleSubmit= ()=>{
  if(!name || !email || !requirements || !phone){
    setError(true);
    return;
  }
  try{
    setProcessing(true)
    const formdata=new FormData()
      formdata.append('name',name)
      formdata.append('email',email)
      
      formdata.append('phone',phone)
      formdata.append('code',' ')

      
      formdata.append('message',requirements)

      formdata.append('category',category)
      
      
      
          fetch(live+'query',
          {
            method:'POST',
            body:formdata
          }).then((resp)=>resp.json())
          .then((res)=>formRes(res))

      

  }catch(error){

  }
}

const formRes=(val1)=>{

  if(val1.status){
    setProcessing(false)

    setSubmitted(true)

  }
  else{
alert(val1.detail)
  setProcessing(false)

  setSubmitted(false)}

}

const handleDataandClose =()=>{
  setChatopen(!chatopen);
  setName('');
  setEmail('');
  setPhone('');
  setRequirements('');
  setOpenform(false);
  setError(false);
  setCategory('')
}

  return (
    <>
      <div
        className='chatbox'
        onClick={() => handleDataandClose()}
      >
        {!chatopen ? <AiFillMessage size={30} /> : <ImCross size={30} />}
      </div>
      {chatopen ? (
        <>
          <div className='chatbox-container' data-aos='fade-right'>
            <div className='upper-box'>
              <img
                src={Logo}
                style={{ width: '100px', padding: '15px 10px' }}
                alt='logo'
              />
              {/* <h1>Welcome to Dec6</h1> */}
              <ImCross
                size={30}
                style={{ color: 'var(--p-red)' }}
                onClick={() => handleDataandClose()}
              />
            </div>
            <div>
              <img
                src={Logo}
                style={{ width: '30px', padding: '2px 10px' }}
                alt='logo'
              />
            </div>
            <p
              className='bot'
              data-aos='fade-right'
              data-aos-duration='500'
              data-aos-delay='100'
            >
              Welcome to Dec6
            </p>
            
            <p
              className='bot'
              data-aos='fade-right'
              data-aos-duration='500'
              data-aos-delay='800'
            >
              Please select your service to continue
            </p>
            {/* Service to avail */}
            <ul
              className='chatbox-container-service-list'
              data-aos='fade-right'
              data-aos-delay='1500'
            >
              {categories&&categories[0]?categories.map((val,idx)=>
              <li ref={myRef} key={idx} onClick={() => Category(val.category)}>{val.category}</li>
              ):null}
                            <li onClick={() => Category('Other')}>{'Other'}</li>

              
            </ul>
            {openform && (
              <div >
                <p
                
                  className='user'
                  data-aos='fade-left'
                  data-aos-duration='500'
                  data-aos-delay='100'
                >
                  Thanks for choosing {category} Service With Dec6 Consulting{' '}
                </p>
                <p
                
                  className='user'
                  data-aos='fade-left'
                  data-aos-duration='500'
                  data-aos-delay='100'
                >
                  {' '}
                  Please fill in your contact details and share your
                  requirements in the below form.
                </p>

                <div  data-aos='fade-left'>
                  <div  className='form '>
                    <input placeholder='Name ' 
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                    className='chatbox-input-box' />
                    {error&& name.length==0 ?<span className='error-message'>Name  is Required</span>:""} 
                    <input placeholder='Email '
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)} className='chatbox-input-box' />
                    {error&& email.length==0 ?<span className='error-message'>Email is Required</span>:""} 

                    <input
                      placeholder='Phone Number'
                      className='chatbox-input-box'
                      value={phone}
                      type='number'
                    onChange={(e)=>setPhone(e.target.value)}
                    />
                    {error&& phone.length==0 ?<span className='error-message'>Phone   is Required</span>:""} 

                    <textarea
                    rows={5}
                      placeholder='Requirements'
                      className='chatbox-input-box'
                      value={requirements}
                    onChange={(e)=>setRequirements(e.target.value)}
                    />
                    {error&& requirements.length==0 ?<span className='error-message'>Requirements is Required</span>:""} 

                    <button
                    
                    type='submit'
                    onClick={() => handleSubmit()}
                    className='chatbox-button'>{processing?'Submitting':!processing&&submitted?'Submitted':'Submit'}</button>
                 
                 
                  </div>

                
                </div>
                {submitted?<p ref={myRef}
               
               className='user'
              
             >
              
              Thanks for submitting your query, our team will connect you shortly
             </p>:null}
             <p
               
style={{maxHeight:10,float:'right',background:'rgb(246, 246, 246)',border:'none'}}              
             >
              
             </p>
             <br /><br/>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {showbutton && (
        <div
          data-aos='fade-left'
          className='backtotop'
          onClick={() => backtotop()}
        >
          <FaArrowAltCircleUp size={32} />
        </div>
      )}
    </>
  )
}

export default ChatbotSection
