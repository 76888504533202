import React from 'react'
import TopSection from './TopSection/TopSection'
import MiddleSection from './MiddleSection/MiddleSection'
import BottomSection from './BottomSection/BottomSection'
import SEO from '../Common/SEO'


const Industries = () => {
  return (
    <>
     <SEO  title={'Industries | Dec6 Consulting Private Limited'}  description={"At Dec6, our people come first, and our vision, purpose, and core values guide our way of being as a company and how we work. We value each person 's uniqueness and encourage our team members to be their authentic selves, nurturing a culture of belonging and inclusion. We aspire for each person to love their work and life."} 
       link={"https://www.dec6.in/industries"} name={'Dec6'} type={'Industries'}/>
      <TopSection />
      <MiddleSection />
      <BottomSection />
</>
  )
}

export default Industries
