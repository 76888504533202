import React, { useEffect } from 'react'
import './GetinTouch.css'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowDropright } from "react-icons/io";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Getintouch = () => {

  const Navigate= useNavigate();

  useEffect(()=>{
    AOS.init();
  },[])

  return (
    <div className='git' data-aos="zoom-out-up">
      {/* <div style={{height:'300px' ,width:'300px',overflow:'hidden', backgroundColor:'var(--t-purpleblue)', position:'absolute',bottom:'-70px', left:'-100px',animation:' mymove 10s linear infinite'}}/> */}
      <div className='GIT-container'>
        <div className='GIT-col-1'>
          <h1>Get in Touch</h1>
          <hr />
        </div>
        <div className='GIT-col-2'>
          <p align='justify'>
          Let’s connect, collaborate and achieve together to elevate your digital realm.
          Our experts are always happy to discuss your needs. We would love to hear
            from you.
          </p>
          <button onClick={()=>Navigate('/contact')} className='git-btn'>Contact us <IoMdArrowDropright size={30}/></button>
        </div>
      </div>
    </div>
  )
}

export default Getintouch
