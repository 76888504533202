import React from 'react'
import TopSection from './TopSection/TopSection'
import MiddleSection from './MiddleSection/MiddleSection'
import BottomSection from './BottomSection/BottomSection'
import SEO from '../Common/SEO'

const Contact = () => {
  return (
    <div>
      <SEO  title={'Contact | Dec6 Consulting Private Limited'}  description={"Dec6 Consulting Private Limited 2/62, Ashri Bhawan, Shivaji Nagar, Gurugram, Haryana India - 12200"} 
       link={"https://www.dec6.in/contact"} name={'Dec6'} type={'Contact'}/>
    
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
    </div>
  )
}

export default Contact
