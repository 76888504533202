import React from 'react'
import TopSection from './TopSection/TopSection'
import BottomSection from './BottomSection/BottomSection'
import MiddleSection from './MiddleSection/MiddleSection'
import SEO from '../Common/SEO'

const About = () => {
  return (
    <div>
      <SEO  title={'About | Dec6 Consulting Private Limited'}  description={"We help you see the world differently, discover opportunities you may never have imagined and unlock the potential to achieve desired results. Together, we help you explore endless possibilities to make this world better."} 
       link={"https://www.dec6.in/about"} name={'Dec6'} type={'About'}/>
    
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
    </div>
  )
}

export default About
