import React from 'react'
import ImageComponent from '../../Common/ImageComponent'
import JoinTop from './JoinTop'
import AboutSection from '../../Common/AboutSection'

const TopSection = () => {
  return (
    <>
      <ImageComponent title={'Join us '} imageSource={require('../../Assets/joinus.jpg')}/>
      <AboutSection title={"Join the expedition "} heading={"We're on a mission, to help customers, communities and businesses overcome barriers and realise their potential."} para={"We are a team of passionate and dedicated individuals who are committed to providing our customers with the best possible experience. We believe that our success is due to our strong work ethic, our commitment to excellence, and our dedication to our customers. We are always looking for new and talented individuals to join our team. If you are interested in working with a company that is committed to your success, then we encourage you to apply."}
      imagesource={require('../../Assets/careerpage.jpg')}
      emaildrop={true}
      />
    </>
  )
}

export default TopSection
