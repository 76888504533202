import React from 'react'
import Getintouch from '../../Common/Getintouch'
import CareerOption from './CareerOption'
import Slider from 'react-slick'

import { IoMdArrowRoundForward, IoMdArrowRoundBack } from 'react-icons/io'
function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <IoMdArrowRoundForward
      className='nextArrow'
      size={10}
      style={{ color: 'var(--p-red)' }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <IoMdArrowRoundBack
      className='prevArrow'
      size={10}
      style={{ color: 'black', zindex: '9' }}
      onClick={onClick}
    />
  )
}

const BottomSection = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <div>
      <div className='join-slider'>
        <Slider {...settings}>
          <CareerOption
            title={'Technology'}
            p1={'Associate Software Engineer'}
            p2={'Associate Consultant'}
          />
          <CareerOption
            title={'Business Development'}
            p1={'Business Development Intern'}
            p2={'Associate Consultant'}
          />
        </Slider>
      </div>
      <Getintouch />
    </div>
  )
}

export default BottomSection
