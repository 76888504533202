import React from 'react'
import HeroSection from './HeroSection'
import AboutSection from '../../Common/AboutSection'
import SectionIntro from '../../Common/SectionIntro'

const TopSection = () => {
  return (
    <div>
      
      <HeroSection/>
      <AboutSection title={"Putting people first since day one"}para={"At Dec6, our people come first, and our vision, purpose, and core values guide our way of being as a company and how we work. We value each person's uniqueness and encourage our team members to be their authentic selves, nurturing a culture of belonging and inclusion. We aspire for each person to love their work and life."} heading={"How dec6 is different"} 
      imagesource={require('../../Assets/aboutSectionCol1.jpg')}
      // inputbox={true}
      button={true}
      link={'./about'}
      
      />
      
      <SectionIntro 
      para={"Dec6 is an IT services, consulting and business solutions organization that has been partnering with many of the world’s largest businesses in their transformation journeys for over 4 years. "}
      imagesource={require('../../Assets/SectionInto1.jpg')} title={"We build sustainable digital solutions through innovation and collective knowledge."}
      />
    </div>
  )
}

export default TopSection
